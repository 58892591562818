@use '@angular/material' as mat;
@mixin trips-theme($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .trips-filter-container {
    background-color: mat.get-color-from-palette($background, card);
  }
}

@use '@angular/material' as mat;
@mixin subheader-theme($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  // #second-subheader-row,
  // .subheader-icons > button {
  //   background-color: mat-color($background, background);
  // }

  .subheader-date,
  .subheader-nav a {
    color: mat.get-color-from-palette($primary);
  }

  .subheader-icons mat-icon {
    color: mat.get-color-from-palette($foreground, icon);
  }

  .subheader-icons mat-icon:hover,
  .subheader-nav a:hover,
  .subheader-nav .selected,
  .subheader-icons .selected {
    color: mat.get-color-from-palette($primary, lighter);
  }
}

@use '@angular/material' as mat;

@include mat.core();

@import '@zonar-ui/material/zonar-ui-material.theme';
@import '@zonar-ui/core/zonar-ui-core.theme';

@import '../app/components/trips/kpi/kpi.theme.scss';
@import '../app/components/trips/trips.theme.scss';
@import '../app/components/shared/subheader/subheader.theme.scss';
@import '../app/components/shared/warning-notification/warning-notification.theme.scss';

@mixin apply-zonar-theme($theme) {
  @include mat.all-component-themes($theme);
  @include zonar-ui-core-theme($theme);
  @include kpi-theme($theme);
  @include trips-theme($theme);
  @include subheader-theme($theme);
  @include warning-notification-theme($theme);
}

.zonar-default-theme {
  @include apply-zonar-theme($zonar-default-theme);
  background-color: #e2e4e6;
}

.zonar-default-theme #second-subheader-row,
.zonar-default-theme .subheader-icons > button,
.zonar-default-theme .mat-drawer-container,
.zonar-default-theme .mat-expansion-panel,
.zonar-default-theme .kpi-header-title,
.zonar-default-theme .kpi-header-icon {
  background-color: #e2e4e6;
}

.zonar-dark-theme {
  @include apply-zonar-theme($zonar-dark-theme);
  background-color: black;
}

.zonar-dark-theme #second-subheader-row,
.zonar-dark-theme .subheader-icons > button {
  background-color: #303030;
}

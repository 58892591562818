@use '@angular/material' as mat;
@mixin kpi-theme($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-expansion-panel,
  .kpi-header-title,
  .kpi-header-icon {
    background-color: mat.get-color-from-palette($background, background);
  }

  .kpi-header-title,
  .kpi-header-icon {
    color: mat.get-color-from-palette($primary, darker);
  }

}
